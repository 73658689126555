body{
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
}


.title {
    display: block;
    font-size: 22px;
    font-weight: 700;
}

.my-gray {
  color: #a9afb4;
}

.form-grp input {
  height: 50px;
}

.try-btn {
  padding: 15px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #1A3263;
  border-radius: 5px;
  color: #fff;
  border: none;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  margin-bottom: 20px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.form-box{
  min-width: 40%;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,.16);
  box-shadow: 0 0 10px rgba(0,0,0,.16);
  border-radius: 5px;
  
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.example-component {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0.7;
  visibility: visible; 
}
.example-component.show {
  opacity: 1;
  visibility: visible; 
}

.text{
  font-size: 15;
  color: '#9399AD';
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.card {
  background: linear-gradient(to right, rgb(12,17,152) , rgb(89,169,163));
  min-height: 200px;
  border-width: 0;
  border-radius: 8px;
  cursor: pointer;
}

.card2 {
  background: linear-gradient(to right, rgb(52,12,152) , rgb(89,120,169));
  min-height: 200px;
  border-width: 0;
  border-radius: 8px;
  cursor: pointer;
}

.card3 {
  background: linear-gradient(to right, rgb(12,131,152) , rgb(34,26,150));
  min-height: 200px;
  border-width: 0;
  border-radius: 8px;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
